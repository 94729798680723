export const formatCurrency = (num) => {
  return Number(Math.round(num)).toLocaleString('en')
}

export const objIsEmpty = (obj) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const formatStatus = (value)  => {
  let newValue = value.replace(/_/g, ' ')
  let finalValue = newValue.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  return finalValue
}
