<template>
  <v-data-table ref="table" :headers="computedHeaders" :items="computedBookings" class="elevation-1"
    :search.sync="search" :loading="loadingTable" :page.sync="pagination.pageNumber"
    :items-per-page.sync="pagination.perPage" :server-items-length="pagination.totalItems" :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc" :footer-props="{
    itemsPerPageOptions: [10, 20, 30, 40, 50],
  }">
    <template v-slot:top>
      <div class="tw-px-2 md:tw-px-6 tw-mb-4">
        <card-title icon="mdi-car" :is-image="false">Internal Assessor</card-title>
      </div>

      <div class="tw-px-2 md:tw-px-6 mt-9">
        <v-btn :outlined="tab !== 'pendingTickets'" color="primary" class="mr-4" @click="tab = 'pendingTickets'">Pending
          Tickets</v-btn>
        <v-btn :outlined="tab !== 'assignedTickets'" color="primary" class="mr-4"
          @click="tab = 'assignedTickets'">Assigned Tickets</v-btn>
        <v-btn :outlined="tab !== 'supplimentaries'" color="primary"
          @click="tab = 'supplimentaries'">Supplementaries</v-btn>
      </div>

      <v-toolbar flat color="white">
        <v-divider class="mx-6" inset></v-divider>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
        </v-text-field>
      </v-toolbar>
      <v-dialog v-model="dialogView" max-width="800px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-container class="text-center">
                <v-card-title>
                  <h3 class="primary--text">Vehicle & User Details</h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <h3 class="text-left grey--text pt-5">
                    Qualified For Assessment
                  </h3>
                </v-card-text>
                <v-card-text>
                  <ul class="text-left">
                    <v-row>
                      <v-col cols="12" md="6">
                        <li>
                          Insured’s Name:<span class="pl-3 primary--text">{{
    viewItem.clientInfo.name
  }}</span>
                        </li>
                        <li>
                          Insured’s Phone Number:<span class="pl-3 primary--text">{{ viewItem.clientInfo.phoneNumber
                            }}</span>
                        </li>
                        <li>
                          Reg No:
                          <span class="pl-3 primary--text">{{
    viewItem.vehicleInfo.registrationNumber
  }}</span>
                        </li>
                        <li>
                          Policy No:
                          <span class="pl-3 primary--text">{{
      viewItem.policyInfo.policyNumber
    }}</span>
                        </li>
                        <li>
                          Sum Assured:
                          <span class="pl-3 primary--text">{{
      viewItem.policyInfo.sumInsured
    }}</span>
                        </li>
                        <li>
                          Endorsement Number:
                          <span class="pl-3 primary--text">{{
      viewItem.policyInfo.endorsementNumber
    }}</span>
                        </li>
                      </v-col>
                      <v-col cols="12" md="6">
                        <li>
                          Policy Type:<span class="pl-3 primary--text">{{
      viewItem.policyInfo.coverType
    }}</span>
                        </li>
                        <li>
                          Policy Description:<span class="pl-3 primary--text">{{
      viewItem.policyInfo.description
    }}</span>
                        </li>
                        <li>
                          Policy Period from:
                          <span class="pl-3 primary--text">{{
      viewItem.policyInfo.effectiveFromDate
    }}</span>
                        </li>
                        <li>
                          Policy Period to:
                          <span class="pl-3 primary--text">{{
      viewItem.policyInfo.effectiveToDate
    }}</span>
                        </li>
                        <li>
                          Renewal Date:
                          <span class="pl-3 primary--text">{{
      viewItem.policyInfo.renewalDate
    }}</span>
                        </li>
                      </v-col>
                    </v-row>
                  </ul>
                </v-card-text>
              </v-container>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAssign" max-width="600px">
        <v-card class="mx-auto">
          <v-container>
            <v-card-text class="mx-auto pt-10">
              <p color="primary">
                Assign the booked vehicles to the available external assessors
              </p>
            </v-card-text>
            <v-card-actions class="mx-auto px-12">
              <v-row class="mt-3">
                <v-col cols="12">
                  <v-autocomplete v-model="selectedInternalAssessor" :items="internalAssessorTeam"
                    :loading="userLoading" label="Search for An External Assessor" prepend-icon="mdi-database-search"
                    chips deletable-chips clearable></v-autocomplete>
                </v-col>
                <v-col class="my-5" cols="12">
                  <v-btn class="secondary mb-5" large block @click="assignedAssessorSubmission">
                    submit</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="recallTicketDialog" max-width="600px">
        <v-card class="mx-auto">
          <v-container>
            <v-card-text class="mx-auto pt-10 text-center">
              <h1 class="text-h5 mb-5 text--primary">Recall Ticket ?</h1>
              <p>
                {{
    `Are you sure you want to recall this ticket you assigned to ${bookingExternalAssessor} ?`
  }}
              </p>
            </v-card-text>
            <v-card-actions class="mx-auto px-12">
              <v-row class="mt-3">
                <v-col cols="6">
                  <v-btn color="primary" class="my-5" outlined large block @click="recallTicketDialog = false">
                    Cancel</v-btn>
                </v-col>
                <v-col class="my-5" cols="6">
                  <v-btn color="primary" class="mb-5" large block :loading="recallLoading"
                    @click="recallTicket('EXTERNAL_ASSESSOR')">
                    Yes</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <span>{{ item.createdAt | formatToHuman }}</span>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div v-if="tab !== 'supplimentaries'">
        <v-chip v-if="item.status === 'INT_ASSESSOR_ASSIGNED'" small color="primary">
          Pending inspection
        </v-chip>
        <v-chip v-if="item.status === 'EXT_ASSESSOR_ASSIGNED'" small color="success" text-color="white">
          Completed
        </v-chip>
      </div>
      <div v-else>
        <v-chip v-if="item.status === 'GARAGE_SUPPLEMENTARY_RECEIVED'"  small  color="primary">
          Pending
        </v-chip>
        <v-chip v-else-if="item.status === 'GARAGE_SUPPLEMENTARY_TO_INT_ASSESSOR'" small color="warning">
          Review
        </v-chip>
        <v-chip v-else small color="success">
          Done
        </v-chip>
      </div>
    </template>

    <template v-if="bookings.length >= 0" v-slot:[`item.actions`]="{ item }">
      <v-row v-if="tab !== 'supplimentaries'">
        <v-col cols="12" md="2">
          <v-btn small text class="mr-3" color="secondary">
            <v-icon color="primary" @click="viewMore(item)"> mdi-eye </v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="item.status === 'INT_ASSESSOR_ASSIGNED'" cols="12" md="8">
          <v-btn small class="ml-5" color="secondary" @click="assignTicket(item)">
            Inspect Vehicle
          </v-btn>
        </v-col>
        <v-col v-if="item.status === 'EXT_ASSESSOR_ASSIGNED'" cols="12" md="8">
          <v-btn small class="ml-5" color="secondary" @click="showRecallTicketDialog(item)">
            Recall Ticket
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-btn v-if="item.status === 'GARAGE_SUPPLEMENTARY_RECEIVED' " 
        text small color="primary" @click="toSupplementaryForm(item)"
        >
          Analyse 
        </v-btn>
        <v-btn v-else-if="item.status === 'GARAGE_SUPPLEMENTARY_TO_INT_ASSESSOR' " 
        text small color="primary" @click="toSupplementaryForm(item)" >
          Confirm 
        </v-btn>
        <v-btn small v-else text color="primary"  @click="toSupplementaryForm(item)">
          <v-icon right dark class="me-1"> mdi-eye </v-icon>
          View</v-btn>
      </v-row>
      <v-snackbar top right v-model="snackbar" :color="snackbarColor">
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-data-table>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import { formatStatus } from '@/utils/helpers'
import CardTitle from '@/components/CardTitle'
import bookingsMixin from '@/mixins/bookingsMixin'
import store from '@/store'

export default {
  name: 'BookedVehicle',
  components: { CardTitle },
  mixins: [bookingsMixin],
  data: () => ({
    dialog: false,
    dialogView: false,
    dialogAssign: false,
    loadingTable: true,
    userLoading: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    bookings: [],
    supplementaryBooking: [],
    category: 'externalAssessor',
    internalAssessorList: [],
    externalAssessorList: '',
    selectedInternalAssessor: '',
    selectedExternalAssessor: '',
    minor: false,
    major: false,
    bookingId: '',
    bookingExternalAssessor: '',
    viewItemIndex: -1,
    viewItem: {
      id: '',
      clientInfo: '',
      createdAt: '',
      vehicleInfo: '',
      policyInfo: '',
      status: '',
    },
    fetchBookingsUrl: '',
    fetchSupplementariesUrl: '',
    supplementaryStatus: 'GARAGE_SUPPLEMENTARY_RECEIVED'
  }),

  computed: {
    internalAssessorTeam: function () {
      return this.internalAssessorList.map((a) => a.attributes['value'])
    },

    pendingTickets: function () {
      return this.bookings.filter(
        (booking) => booking.status === 'INT_ASSESSOR_ASSIGNED'
      )
    },

    assignedTickets: function () {
      return this.bookings.filter(
        (booking) => booking.status === 'EXT_ASSESSOR_ASSIGNED'
      )
    },

    computedBookings: function () {
      if (this.tab === 'pendingTickets') return this.pendingTickets
      else if (this.tab === 'assignedTickets') return this.assignedTickets
      else return this.supplementaryBooking
    },

    computedHeaders() {
      if (this.tab === 'supplimentaries') return this.supplementaryHeaders
      return this.headers
    }
  },

  mounted: function () {
    this.fetchBookingsUrl = `/motor-assessment/api/booking?bookingRequestType=INTERNAL_ASSESSOR_ASSIGNED_BOOKINGS&userId=${this.$store.state.auth.user.sub}`
    this.fetchSupplementariesUrl = `/motor-assessment/api/garages/supplementary/${this.$store.state.auth.user.sub}`
    this.fetchBookings()
    this.fetchSupplementaries()
    this.sortBy = [false, false, false, false]
  },

  methods: {
    viewMore(item) {
      this.viewItemIndex = this.bookings.indexOf(item)
      this.viewItem = { ...item }
      this.dialogView = true
    },
    assignTicket(item) {
      this.dialogAssign = true
      this.userLoading = true
      this.bookingId = item.id
      const userPayload = {
        userPool: process.env.VUE_APP_USER_POOL_ID,
        userGroup: this.category,
        limit: 60,
        region: store.getters['auth/authenticatedUser']['custom:region'],
      }
      axios
        .post('/motor-assessment/api/users/list', userPayload)
        .then((res) => {
          const users = res.data.data
          let newArr = users.map((user) => {
            this.newObj = {}
            this.newObj.attributes = user.attributes.find(
              (attribute) => attribute.name === 'custom:name'
            )
            this.newObj.username = user.username
            return this.newObj
          })
          for (let user of newArr) {
            this.internalAssessorList.push(user)
          }
          this.userLoading = false
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
          this.userLoading = false
        })
    },
    showRecallTicketDialog(item) {
      this.bookingId = item.id
      this.bookingExternalAssessor = this.scanObject(
        item,
        'externalAssessor.name'
      )
      this.recallTicketDialog = true
    },
    minorAccident() {
      this.minor = true
      this.major = false
    },
    majorAccident() {
      this.major = true
      this.minor = false
    },

    assignedAssessorSubmission() {
      const selectedAssessor = this.internalAssessorList.filter(
        (internalAssessor) =>
          internalAssessor.attributes['value'] === this.selectedInternalAssessor
      )
      const assessorCode = selectedAssessor[0].username
      axios
        .post(
          `/motor-assessment/api/tickets/major-accident/assign/${this.bookingId}?externalAssessorUserName=${assessorCode}`
        )
        .then((res) => {
          this.snackbar = true
          this.snackbarMessage = res.data.msg
          this.snackbarColor = 'success'
          this.fetchBookings()
        })
        .catch((err) => {
          this.snackbar = true
          this.snackbarMessage = err.response.data.message
          this.snackbarColor = 'error'
        })
      this.dialogAssign = false
    },
    toSupplementaryForm(bid) {
      this.$store.commit('bidForm/updateSupplementaryForm', bid)
      this.$router.push({ name: 'SupplementaryReview'})
    }
  },
  filters: {
    formatToHuman,
    formatStatus
  },
}
</script>
